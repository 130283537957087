import { ConfigProvider, theme } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from "react-router-dom";

import { dontDisplayAllItemsItems } from "reducer/action/coreActions";

import AppRoute from './AppRoute'
import User from './lib/keepsec_auth_service'
import store from './reducer'
import { ThemeContext, ThemeProvider } from './theme/ThemeContext';
import Utils from './utils'

import './App.css';
import './commingSoon.css';
import "./credit.css"
import "./mars.css"

function KeepSec({pathname}) {
    const [passCheckAuth, setPassCHeckAuth] = React.useState(false)
    const user = useSelector(app => app.user?.user)
    const dispatch = useDispatch()

    function checkpreService(){
        if(Utils.getUrlParam("r")){ 
            window.localStorage.setItem('@user_first_service', "/"+Utils.getUrlParam("r")+"?prefer="+Utils.getUrlParam("prefer"))
        }
    }
   

   async function retrive() {
        const u = localStorage.getItem('xsrfToken')
        if(u){
            await User.refrest_token()
        }
    }
    React.useEffect(() => {
        (async()=>{
            const stilDmo = localStorage.getItem("stillOnDemo") === "true"
            dispatch(dontDisplayAllItemsItems(stilDmo)) 
            checkpreService(); 
            retrive().then(() =>{
                setPassCHeckAuth(true)
            })
        })()
    }, [])
    React.useMemo(() => {
        if(Utils.getUrlParam("referrerId")){
            window.localStorage.setItem('referrerId', Utils.getUrlParam("referrerId"))
        }
    }, [])

    if (!passCheckAuth){
        return (
            <div className='flex justify-center h-screen w-screen'>
                <svg className="animate-spin  h-12 w-12 text-primary dark:text-blue-700 mx-auto my-auto"  fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        )
    }

    return (
            <AppRoute loginForce={!user && pathname !== "login"} stillLoading={passCheckAuth}/>
        
    )
}

function Background({ children } ){
    const conext = React.useContext(ThemeContext);
    const { i18n } = useTranslation();
    
    useMemo(async ()=>{
        const keepsecUserLang = Utils.getCookie("keepsecUserLang")
        if(keepsecUserLang) i18n.changeLanguage(keepsecUserLang)
            Utils.deleteCookie("keepsecUserLang")
    }, [])

    return(
        <ConfigProvider
            theme={{
                algorithm: conext?.theme ==="dark" && theme.darkAlgorithm,
                token: {
                    colorPrimary: '#011a43',
                },
                components: {
                  Badge: {
                    // dotSize: 10
                  },
                },
            }}
        >
        <div className={` ${conext?.theme} dark:bg-black transition-all h-screen`}>
            {children}
        </div>
    </ConfigProvider>
    )
}

function App() {
    const { pathname } = useLocation();
    return (
        <ThemeProvider>
            <Background>
                <Provider store={store}>
                    <KeepSec pathname={pathname} />
                </Provider>
            </Background>
        </ThemeProvider>
    );
}

export default App;

