import { Button, Modal } from 'antd';
import React from 'react'
import { useEffect, useState } from 'react';
import { EditText, EditTextarea } from 'react-edit-text';
import { TabPanel, useTabs } from "react-headless-tabs";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckSVG } from 'components/Bloc';
import { Texto } from 'layouts/TextoLayout';

import { TabSelector } from "../../components/TabSelector";
import UserAPI from "../../lib/keepsec_auth_service"
import UserMgt from "../../lib/user_managment"
import { ThemeContext } from '../../theme/ThemeContext';
import TwoFactorSetup from './TwoFactorSetup'

import 'react-edit-text/dist/index.css';

function DisableTwoFa({open, setOpen, onFetchProfile}){
    const {messageApi} = useSelector(app => app.core )
    const [ongoing, setOnGoing] = useState(false)
    const {t} = useTranslation()

    async function disabledTwoFa(){
        setOnGoing(true)
        const result = await UserAPI.disableTwoFa()
        setOnGoing(false)
        if(result?.error){
            messageApi.error(t('core.constants.STANDARD_ERROR_MESSAGE'));
        }
        setOpen(false)
        messageApi.success("account.profile.twoFa.disbaleTwoFaModal.successMessage");
        onFetchProfile()
    }
    return(
        <Modal
            destroyOnClose={true}
            footer={null}
            centered
            closeIcon={false}
            className='mPadding'
            width={600}
            open={open}
        >
            <div className=''>
                <div className='bg-primary py-5 px-6 text-white'>
                    <div className=''>
                        <h4 className="font-bold text-base xl:text-xl">
                            {t("account.profile.twoFa.disbaleTwoFaModal.title")}
                        </h4>
                        {/*<p className='text-sm xl:text-base'>
                            Pick new size to increase. 
                        </p>*/}
                    </div>
                    
                </div>
                <div className="overflow-y-auto ">
                    <div className="w-full text-sm xl:text-base font-semibold px-6 py-10">                       
                        <Texto small>
                            {t("account.profile.twoFa.disbaleTwoFaModal.p1")}
                            
                        </Texto>
                        <Texto small className="mt-2">
                            {t("account.profile.twoFa.disbaleTwoFaModal.p2")}
                        </Texto>
                    </div>
                </div>
                <div className="py-5 flex gap-2 justify-end  px-6">
                   
                        <Button size="large" 
                            onClick={() => {
                                // setData({})
                                setOpen(false); 
                            }}
                        >
                            {t("account.profile.twoFa.disbaleTwoFaModal.nobtn")}
                        </Button>
                        <Button 
                            className="bg-red-500" 
                            danger
                            loading={ongoing}
                            style={{color: "white"}}
                            size="large" 
                            // type="primary"  
                            onClick={disabledTwoFa}
                            >
                            {t("account.profile.twoFa.disbaleTwoFaModal.yesBtn")}
                        </Button>
                    
                </div>
            </div>
        </Modal>
    )
}
function TwoFactorCard({user, onFetchProfile, codeRemainArr}){
    const [open, setTwoFaModalVisible] = useState(false)
    const {t} = useTranslation()

    return(
        <div>
            <div className="flex gap-2 flex-wrap items-end text-gray-700">
                <div>
                    <Texto small className="font-semibold">
                        {t("account.profile.twoFa.default")}
                    </Texto>
                    <div className="py-2 px-4 mt-2 border-2 rounded-md border-gray-300 w-[300px] shadow-lg">
                        <p className="font-bold  text-sm xl:text-base">
                            {
                                user?.twoFaMethod === "app" ?
                                "Authentificator App"
                                :
                                "SMS Authentificator"
                            }
                        </p>
                    
                        <Texto className="mt-2 font-semibold">
                             {t("account.profile.twoFa.configured")}
                        </Texto>
                    </div>
                </div>
                <div>
                    <Texto small className="font-semibold">
                         {t("account.profile.twoFa.backup")}
                    </Texto>
                    <div className="py-2 px-4 mt-2 border-2 rounded-md border-gray-300 w-[300px] shadow-lg">
                        <Texto className="font-bold ">
                            {t("account.profile.twoFa.backupCodes")}
                        </Texto>
                        <Texto className=" mt-2  font-semibold">
                            {codeRemainArr?.length} {t("account.profile.twoFa.remaining")}
                        </Texto>
                    </div>
                </div>
                <div className="pb-3">
                    <Button 
                        className="w-32 font-semibold h-10 2xl:h-12 rounded" 
                        type="primary"
                        onClick={() =>setTwoFaModalVisible(true)}
                    >
                         {t("account.profile.twoFa.disableBtn")}
                    </Button>
                </div>
            </div>
            <DisableTwoFa 
                open={open} 
                setOpen={setTwoFaModalVisible} 
                onFetchProfile={onFetchProfile} 
            />
        </div>
    )
}


export default function Profile() {
    const [codeRemainArr, setCodeRemainTab] = useState([])
    const user2 = useSelector(app => app?.user?.user)
    const [user, setUser] = useState({})
    const {t} = useTranslation()
    const { messageApi } = useSelector(app => app.core)
    const [resetPassObj, setResetPassObj] = React.useState({
        oldPassword: null,
        newPassword: null,
        newConfirmPassord: null
    });
    const navigate = useNavigate()
    const [resetPassOngoing, setResePassOngoing] = React.useState(false)
    const { theme, setTheme } = React.useContext(ThemeContext);

    const [selectedTab, setSelectedTab] = useTabs([
        "Profile",
        "Preferences",
        "Authentification",
        "Security"
    ]);
    


    useEffect(()=>{
        //UserMgt.get_user_payment() 
    }, [])

    async function onFetchProfile(){
        const res = await UserMgt.get_user_profile(user2?.Id) 
        setUser(res)

        const tab = res?.recovery2FaCode?.split("-")
        const finalTab = []
        for(let x = 0; x < tab?.length; x++){
          const subtab = tab[x].split(',')
          for(let j = 0; j < subtab.length; j++){
            finalTab.push(subtab[j])
          }
        }
        setCodeRemainTab(finalTab.filter(t => t !== ""))

    }
    useEffect(()=>{
        onFetchProfile()
    }, [])
    const handleChangeResetPassword = (e) => {
      const newResetObj = {
        ...resetPassObj,
        [e.target.name]: e.target.value
      }
      setResetPassObj(newResetObj)
    };


    async function OnSubmitResetPassword(){
        try{
            if( !resetPassObj.newPassword 
                || !resetPassObj.newConfirmPassord 
                ||  resetPassObj.newPassword !== resetPassObj.newConfirmPassord
                || !resetPassObj.oldPassword
            ){
               return messageApi.error(t('core.constants.MISSING_REQUIRED_FIELD'))
            }
            setResePassOngoing(true)

            const results = await  UserAPI.reset_pass_when_login(resetPassObj)
            console.log('ressult reset', results, resetPassObj)
            if(!results || results?.status !== "success"){
                messageApi.error(results.message || t('core.constants.STANDARD_ERROR_MESSAGE'))
                setResePassOngoing(false)
            }else{ 
                messageApi.success(results?.message)
                setResePassOngoing(false)
                return navigate('/') 
            }
        }catch(e){
            console.error('OnError resetPass', e)
            setResePassOngoing(false)
            messageApi.error(t('core.constants.STANDARD_ERROR_MESSAGE'))
        }
    }
    const onUpdate2 = async (data) => {
        try {
            // return;
            const result = await UserMgt.on_update_profile(data)
            onFetchProfile()
        } catch (error) {
            console.log('error onUpdate2')
        }
    }
    const handleChange = (e, saved) => {
        console.log('user user user Chaneg', e)
        setUser({
            ...user,
            [e.name]: e.value
        });
      if(saved) return onUpdate2({[e.name]: e.value})
    };

    // async function onConfirmEmail(){ 
    //     setEmailIsSending(true)
    //     try {
    //         const result = await UserMgt.reSendEmailVerification({EmailAddr: user?.EmailAddr, FirstName: user?.FirstName})
    //         console.log('result result result', result)
    //         Fire.fire({
    //             title: <strong>Good job!</strong>,
    //             html: <i>{result?.message}</i>,
    //             icon: 'success'
    //           })
    //     } catch (error) {
    //         console.log('onConfirm error', error)
    //     }
    //     setEmailIsSending(false)
    // }

    return (
        <div>
            <div className=' dark:text-darkTextColor'>
                <div className="px-3 md:px-8 ">
                    <div className="pt-5">

                        <nav className="flex border-b border-gray-300 dark:border-gray-800">
                            <TabSelector
                              isActive={selectedTab === "Profile"}
                              onClick={() => setSelectedTab("Profile")}
                            >
                              {t("account.profile.profileTab.name")}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Preferences"}
                              onClick={() => setSelectedTab("Preferences")}
                            >
                              {t("account.profile.preferencesTab.name")}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Authentification"}
                              onClick={() => setSelectedTab("Authentification")}
                            >
                              {t("account.profile.authentificationTab.name")}
                            </TabSelector>
                            <TabSelector
                              isActive={selectedTab === "Security"}
                              onClick={() => setSelectedTab("Security")}
                            >
                              {t("account.profile.securityTab.name")}
                            </TabSelector>
                           
                          </nav>
                          <div className="pl-5 pt-4">
                            <TabPanel hidden={selectedTab !== "Profile"}>
                                <div className="pt-5 md:pr-20 w-full md:w-3/4">
                                    {/* {user && !user?.EmailConfirmed ?

                                        <Space
                                            direction="vertical"
                                            style={{
                                            width: '100%',
                                            paddingBottom: "15px"
                                            }}
                                        >
                                            <Alert
                                                message=" You have to confirm your email address first to be able to use KeepSec cloud services"
                                                type="warning"
                                                action={
                                                    <Space direction="vertical">
                                                    <Button onClick={onConfirmEmail} className='px-5' loading={emailIsSending} type="primary">
                                                        Yes confirm it
                                                    </Button>
                                                    </Space>
                                                }
                                                className='py-6'
                                            />
                                        </Space>
                                        :
                                        null
                                    } */}
                                    <React.Fragment>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    placeholder={t("account.profile.profileTab.firstName")}
                                                    name={"FirstName"} 
                                                    value={user?.FirstName}
                                                    type="text"
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                            <div className="w-full md:w-1/2 px-3">
                                                <EditComponent 
                                                    name={"LastName"} 
                                                    value={user?.LastName}
                                                    placeholder={t("account.profile.profileTab.lastName")}
                                                    type="text"
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full   px-3 mb-6 md:mb-0">
                                                {/* <Tooltip title={
                                                        user?.EmailConfirmed ?
                                                        "Your email address is now confirmed, so you start using KeepSec cloud services":
                                                        "Please confirm your email first before using KeepSec cloud service "
                                                    }
                                                    className='w-full'
                                                > */}
                                                    <span>
                                                    <EditComponent 
                                                        name={"emailAddr"} 
                                                        placeholder={t("account.profile.profileTab.email")}
                                                        type="email"
                                                        value={user?.EmailAddr}
                                                        handleChange={handleChange}
                                                        readonly

                                                    /></span>
                                                {/* </Tooltip> */}
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"PhoneNumber"} 
                                                    value={user?.PhoneNumber}
                                                    placeholder={t("account.profile.profileTab.phonenumber")}
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"Company"} 
                                                    value={user?.Company}
                                                    placeholder={t("account.profile.profileTab.company")}
                                                    type="text"
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                        
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"Address"} 
                                                    placeholder={t("account.profile.profileTab.address")}
                                                    value={user?.Address}
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"Address2"} 
                                                    placeholder={t("account.profile.profileTab.address2")}
                                                    value={user?.Address2}
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full md:w-3/5 px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"City"} 
                                                    placeholder={t("account.profile.profileTab.city")}
                                                    value={user?.City}
                                                    type="text"
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                            <div className="w-full md:w-2/5 px-3">
                                                <EditComponent 
                                                    name={"ZipCode"} 
                                                    placeholder={t("account.profile.profileTab.ZipCode")}
                                                    type="text"
                                                    value={user?.ZipCode}
                                                    handleChange={handleChange}

                                                />
                                            </div>
                                        </div>

                                        {/* <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"PhoneNumber"} 
                                                    value={user?.PhoneNumber}
                                                    placeholder="Phone number"
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div> */}

                                        <div className="flex flex-wrap -mx-3 mb-2">
                                            <div className="w-full  px-3 mb-6 md:mb-0">
                                                <EditComponent 
                                                    name={"Website"} 
                                                    value={user?.Website}
                                                    placeholder={t("account.profile.profileTab.website")}
                                                    type="text"
                                                    handleChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                         <div className="flex flex-wrap -mx-3 ">
                                            <div className="w-full  px-3 mb-6 md:mb-8  text-gray-700">
                                                <Texto small>
                                                    {t("account.profile.profileTab.descirptionTextBefore")}
                                                </Texto>
                                                <EditTextarea
                                                    name='Description'
                                                    className="bg-grey dark:bg-bgPagedark"
                                                    style={{ fontSize: '16px', border: '1px solid #ccc' }}
                                                    onChange={(e)=>handleChange({name: e.target.name, value: e.target.value})}
                                                    onSave={(e)=>handleChange(e, true)}
                                                    inputClassName="
                                                        block
                                                        bg-white
                                                        hover:bg-ligth
                                                        tracking-wide
                                                        text-gray-700 text-xs
                                                        font-bold
                                                        mb-2
                                                        dark:bg-bgPagedark
                                                        dark:text-darkTextColor
                                                    "
                                                    //onSave={handleSave}
                                                    value={user?.Description}
                                                    placeholder={t("account.profile.profileTab.desc")}
                                                  />
                                            </div>
                                        </div>

{/* 
                                        <div className="flex flex-wrap  mb-6">
                                            <button className=" text-center text-xl  w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                                type="submit"
                                                // disabled={updateOngoing}
                                                onClick={onUpdate2}
                                            >
                                                 <span className='mx-auto flex justify-center gap-1 items-center'>
                                                    {resetPassOngoing?
                                                        <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        :
                                                        null
                                                    }
                                                    {resetPassOngoing ? " Ongoing" :  " Update profil"}
                                                </span>
                                                
                                            </button>
                                        </div> */}

                                    </React.Fragment>

                                </div>
                            </TabPanel> 

                          <TabPanel hidden={selectedTab !== "Preferences"}>
                               <div className=" pt-5">
                                    <Texto normal className=" font-semibold">
                                        {t("account.profile.preferencesTab.title")}
                                    </Texto>
                                    
                                    <div>
                                        <div  
                                        onClick={() => setTheme('light' )}
                                        onMouseOver={() => {
                                                //setSelectedImage(image.id)
                                            }
                                        }  className={'w-48 mt-5 dark:bg-bgPagedark  ml-10  cursor-pointer rounded-xl ' +
                                            (true ? "hover:bg-white dark:hover:shadow-darkSiderbar   hover:shadow-2xl " : '') + "" + (true ? "bg-white shadow-xl" : 'bg-neutral-100')}>

                                            {(theme==='light') &&
                                                <CheckSVG />
                                            }

                                            <div className=' p-5 pb-2 text-center '>
                                                {/*<img src={light} alt="" className='w-24 h-20 mx-auto' />*/}
                                                <div className="lighBg w-24 h-20 mx-auto" />
                                                &nbsp;&nbsp;
                                                <p className='text-primary dark:text-darkTextColor font-semibold text-xl'>{t("account.profile.preferencesTab.light")}</p>
                                            </div>
                                        </div>

                                        <div 
                                        onClick={() => setTheme('dark')}
                                        onMouseOver={() => {
                                                //setSelectedImage(image.id)
                                            }
                                        }  className={'w-48 dark:bg-bgPagedark mt-5 ml-10 cursor-pointer rounded-xl ' +
                                            (true ? "hover:bg-white  hover:shadow-2xl dark:hover:shadow-darkSiderbar " : '') + "" + (false ? "bg-white shadow-xl" : 'bg-neutral-100')}>

                                            {(theme === "dark") &&
                                                <CheckSVG />
                                            }

                                            <div className=' p-5 pb-2 text-center'>
                                            <div className="darkBg w-24 h-20 mx-auto" />
                                                {/*<img src={dark} alt="" className='w-24 h-15 mx-auto' />*/}
                                                &nbsp;&nbsp;
                                                <p className='text-primary dark:text-darkTextColor font-semibold text-xl'>{t("account.profile.preferencesTab.dark")}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex flex-wrap  mb-6 pt-5">
                                                <div className="w-full md:w-1/2  mb-6 md:mb-0">
                                                    <label
                                                        className="
                                                            block
                                                            
                                                            tracking-wide
                                                            text-gray-700 text-base
                                                            font-bold
                                                            mb-2
                                                        "
                                                        htmlFor="serv-tz"
                                                    >
                                                        {t("account.profile.preferencesTab.dTimezone")}
                                                    </label>
                                                    <select
                                                        className="
                                                            appearance-none
                                                            block
                                                            w-full
                                                            bg-gray-200
                                                            text-gray-700
                                                            border
                                                            rounded
                                                            py-3
                                                            px-4
                                                            mb-3
                                                            leading-tight
                                                            focus:outline-none focus:bg-white
                                                            dark:bg-bgPagedark
                                                            dark:text-darkTextColor
                                                        "
                                                        id="serv-tz"
                                                        //value={""}
                                                        name="server_name"
                                                        //onChange={handleChange}
                                                        required
                                                        //disabled={creating}
                                                        placeholder={t("account.profile.preferencesTab.timezonePlaceholder")}
                                                    >
                                                        <option>1</option>
                                                        <option>2</option>
                                                    </select>
                                                </div>
                                            </div> 

                                            <div className="flex flex-wrap ">
                                                <div className="w-full md:w-1/2  mb-6 md:mb-0">
                                                    <label
                                                        className="
                                                            block
                                                            
                                                            tracking-wide
                                                            text-gray-700 text-base
                                                            font-bold
                                                            mb-2
                                                        "
                                                        htmlFor="serv-df"
                                                    >
                                                       {t("account.profile.preferencesTab.dateFormat")}
                                                    </label>
                                                    <select
                                                        className="
                                                            appearance-none
                                                            block
                                                            w-full
                                                            bg-gray-200
                                                            text-gray-700
                                                            border
                                                            rounded
                                                            py-3
                                                            px-4
                                                            mb-3
                                                            leading-tight
                                                            dark:bg-bgPagedark
                                                            dark:text-darkTextColor
                                                            focus:outline-none focus:bg-white
                                                        "
                                                        id="serv-df"
                                                        //value={""}
                                                        name="server_name"
                                                        //onChange={handleChange}
                                                        required
                                                        //disabled={creating}
                                                    >
                                                        <option>
                                                         {t("account.profile.preferencesTab.choice1")}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>


                                            <div className="flex flex-wrap  mb-6">
                                                <button className=" mt-4 text-center text-xl  w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                                    type="submit"
                                                    //disabled={creating}
                                                >
                                                    {t("account.profile.preferencesTab.updateProfilBtn")}
                                                    
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          </TabPanel>

                           <TabPanel hidden={selectedTab !== "Authentification"}>
                                <div className=" pt-5">
                                    <Texto normal className=" font-semibold">{t("account.profile.authentificationTab.title")}</Texto>
                                    <div>
                                        <span>
                                            {t("account.profile.authentificationTab.p1")}<br/>
                                            {t("account.profile.authentificationTab.p2")}
                                        </span>
                                        <br/>
                                        <br/>
                                        {user?.is2FaVerified ?
                                        <TwoFactorCard codeRemainArr={codeRemainArr} user={user} onFetchProfile={onFetchProfile} />
                                            :
                                        <TwoFactorSetup onFetchProfile={onFetchProfile} />
                                        }
                                         
                                        {/*Your email must be verified before proceeding with two-factor authentification*/}
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6 pt-5">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="
                                                    block
                                                    
                                                    tracking-wide
                                                    text-gray-700 text-base
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="oldPassword"
                                            >
                                                {t("account.profile.authentificationTab.currentPass")}
                                            </label>
                                            <input
                                                className="
                                                    appearance-none
                                                    block
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    leading-tight
                                                    focus:outline-none focus:bg-white
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                "
                                                id="oldPassword"
                                                value={resetPassObj?.oldPassword || ""}
                                                name="oldPassword"
                                                onChange={handleChangeResetPassword}
                                                required
                                                type='password'
                                                disabled={resetPassOngoing}
                                                placeholder={t("account.profile.authentificationTab.currentPassPlaceholder")}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="
                                                    block
                                                    
                                                    tracking-wide
                                                    text-gray-700 text-base
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="newPassword"
                                            >
                                                {t("account.profile.authentificationTab.newPass")}
                                            </label>
                                            <input
                                                className="
                                                    appearance-none
                                                    block
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                    leading-tight
                                                    focus:outline-none focus:bg-white
                                                "
                                                id="newPassword"
                                                value={resetPassObj?.newPassword || ""}
                                                name="newPassword"
                                                onChange={handleChangeResetPassword}
                                                required
                                                type='password'
                                                disabled={resetPassOngoing}
                                                placeholder={t("account.profile.authentificationTab.newPassPlaceholder")}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="
                                                    block
                                                    
                                                    tracking-wide
                                                    text-gray-700 text-base
                                                    font-bold
                                                    mb-2
                                                "
                                                htmlFor="newConfirmPassord"
                                            >
                                                {t("account.profile.authentificationTab.confirmNew")}
                                            </label>
                                            <input
                                                className="
                                                    appearance-none
                                                    block
                                                    dark:bg-bgPagedark
                                                    dark:text-darkTextColor
                                                    w-full
                                                    bg-gray-200
                                                    text-gray-700
                                                    border
                                                    rounded
                                                    py-3
                                                    px-4
                                                    mb-3
                                                    leading-tight
                                                    focus:outline-none focus:bg-white
                                                "
                                                id="newConfirmPassord"
                                                value={resetPassObj?.newConfirmPassord || ""}
                                                name="newConfirmPassord"
                                                onChange={handleChangeResetPassword}
                                                required
                                                type='password'
                                                disabled={resetPassOngoing}
                                                placeholder={t("account.profile.authentificationTab.confirmNewPlcaholder")}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap  mb-6">
                                        <button className=" mt-4 text-center text-xl  w-72 text-center items-center shadow bg-primary  focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                                            type="submit"
                                            disabled={resetPassOngoing}
                                            onClick={OnSubmitResetPassword}
                                        >
                                            <span className='mx-auto flex justify-center gap-1 items-center'>
                                                {resetPassOngoing?
                                                    <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    :
                                                    null
                                                }
                                                {resetPassOngoing ? t("account.profile.authentificationTab.ongoing") : t("account.profile.authentificationTab.change")}
                                            </span>
                                        </button>
                                    </div>
                                   
                                </div>
                          </TabPanel>
                         </div> 

                    </div>

            
                </div>
            </div>
        </div>
    );
}

function EditComponent(props){
    return(
        <div>
            {/*{props.value&&*/
               <div  className="
                                block
                                
                                tracking-wide
                                text-gray-700 text-base
                                font-bold
                                mb-2
                            "
                            >
                     {props.placeholder}
                </div>
            }            
            <div>
                <EditText
                    name={props.name}
                    type={props.type  || "text"}
                    style={{
                      // fontSize: '16px',
                      // border: '1px solid #011a43',
                      // marginBottom: '10px',
                      borderRadius: "5px",
                      height: "40px"
                    }}
                    className="bg-grey dark:bg-bgPagedark
                                                        

                    "
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(e) => props.handleChange({name: e.target.name, value: e.target.value})}
                    onSave={(e) => props.handleChange(e, true)}
                    // formatDisplayText={formatPrice}
                    readonly={props.readonly}
                    inputClassName="
                        appearance-none
                        block
                        w-full
                        dark:bg-bgPagedark
                        dark:text-darkTextColor
                        bg-gray-200
                        text-gray-700
                        border
                        rounded
                        py-3
                        px-4
                        mb-3
                        leading-tight
                        focus:outline-none focus:bg-white

                    "
                />
            </div>
        </div>
    )
}


