import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';

import { Logo } from '../../components/Logo';
import { Toast } from '../../components/Notify';
import { SocialLogin } from '../../components/SocialLogin';
import UserAPI from "../../lib/keepsec_auth_service"

import "./Auth.css"

export default function Login() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [objectLogin, setObject] = useState({})
    const [creating, setCreating] = useState(false) 
    const navigate = useNavigate()
    const user = useSelector(app => app?.user?.user)
    // const [Gaccess_token, setGaToken] = useState(null)

    useEffect(() => {
        if(user) return navigate('/')
        
        const url = window.location.href;
    }, [])

    const handleChange = (ev) => {
        const {value, name} =  ev.target
        setObject({...objectLogin, [name]: value})
    }
    


    async function onLogin(e, otherAuth){
        e && e.preventDefault()
     
        !otherAuth && setCreating(true)
        try {
            const result = await UserAPI.user_login(e ? objectLogin : otherAuth, dispatch)
            console.log('result', result)
            !otherAuth && setCreating(false)
            if(result.error){
                return Toast.fire({
                    icon: 'error',
                    title: result.message
                })
            }else{ 
                if(result?.twoFac){
                    return navigate('/2-fac-check')
                }
                const referrerId = localStorage.getItem('referrerId')
                if(referrerId) localStorage.removeItem('referrerId')
                Toast.fire({
                    icon: 'success',
                    title: t('Signed in successfully')
                })
                
                navigate('/')
                
            }
        } catch (error) {
            !otherAuth && setCreating(false)
            console.error('error api', error)
            Toast.fire({
                icon: 'error',
                title: error.message
            })
        }
        //navigate('/')

    }
    return (
        <div className='bgCustom'>
            {/* <video autoplay={true} muted={true} loop={true} className="myVideo">
                <source src={require('../../assets/img/keepsecbackgroud.mp4')} type="video/mp4"/>
            </video> */}

            <div className="flex items-center justify-center min-h-screen  content ">
                <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-2xl w-[400px]">
                    <div>
                        <Logo/>
                        <h2 className="mt-4 text-3xl font-extrabold text-center text-gray-900">
                            {t('Log in to your account')}
                        </h2>
                        {/* <p className="mt-2 text-sm text-center text-gray-600">
                            Not a member yet?<br />
                            <NavLink
                                to="/register"
                                className={"font-medium text-primary hover:text-blue-500 cursor-pointer"}
                            >
                                Sign up
                            </NavLink>
                              
                        </p> */}
                    </div>
                    <form className="mt-3 space-y-6" method="POST" onSubmit={onLogin}>
                        <div className="flex flex-wrap -mx-3 mb-0">
                            <div className="w-full px-2 mb-0 md:mb-0">
                                <label className='font-semibold' htmlFor="email">{t('Email')} <span className="text-red-500">*</span></label>
                                <Input 
                                    className="
                                        w-full
                                        bg-gray-200s
                                        text-gray-700
                                        rounded-md
                                        dark:bg-transparent
                                        py-1
                                        px-4
                                        mb-2
                                    "
                                    value={objectLogin.email}
                                    onChange={handleChange}
                                    name='email'
                                    required
                                    type="email"
                                    id="email"
                                    placeholder={t("Email here")}
                                 />
                               
                            </div>
                            <div className="w-full px-2 pt-2">
                                
                                <label className='font-semibold' htmlFor="password">{t('Password')} <span className="text-red-500">*</span></label>
                                <Input 
                                    className="
                                        w-full
                                        bg-gray-200s
                                        text-gray-700
                                        py-1
                                        px-4
                                        mb-3
                                        rounded-md
                                        dark:bg-transparent
                                    "
                                    value={objectLogin.password}
                                    onChange={handleChange}
                                    name='password'
                                    required
                                    type="password"
                                    id="password"
                                    placeholder={t("Password here")}
                                    />
                            </div>
                        </div>

                        

                        <div>
                            <button
                                type="submit"
                                className="
                                relative
                                flex
                                justify-center
                                items-center
                                w-full
                                px-4
                                py-2
                                text-base
                                font-medium
                                text-white
                                bg-primary
                                group
                                rounded-md
                                hover:bg-black hover:border-primary
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-blue-500
                                gap-2
                                "
                            >
                                {!creating ?
                                    <svg
                                        className="w-5 h-5 text-white group-hover:text-indigo-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    :
                                    <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white"  fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                }
                                {!creating ?
                                    <span>{t('Log in')}</span> :
                                    <span>{t('Ongoing')} ...</span>
                                }
                            </button>
                        </div>
                    </form>

                  
                    
                    <SocialLogin setCreating={setCreating} onCallback={onLogin} />
                    

                    <div className="flex items-center justify-between">
                        <div className="flex items-center text-xs">
                            {t('Not a member yet ?')} &nbsp;
                            <Link
                                to="/registrations/new"
                                className={""}
                            >
                                <span className="font-bold text-primary hover:text-blue-500 cursor-pointer"
                                >
                                    {t('Sign up')}
                                </span>
                            </Link>
                        </div>

                        <div className="text-xs">
                            <Link
                                to="/forgot_password/new"
                                className={""}
                            >
                                <span className="font-bold text-primary hover:text-blue-500 cursor-pointer"
                                >
                                    {t('Forgot your password?')}
                                </span>
                            </Link>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
    );
}












