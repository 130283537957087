import { DownOutlined, LogoutOutlined, QuestionCircleFilled, SettingFilled, UserOutlined } from '@ant-design/icons';
import { NovuProvider, PopoverNotificationCenter, useSocket } from "@novu/notification-center";
import { Badge, Button, Checkbox, Divider, Dropdown, Popover, Radio, Select, Space } from 'antd';
import { Crisp } from "crisp-sdk-web";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdNotifications } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

// import LanguageDetector from 'i18next-browser-languagedetector';
import { dontDisplayAllItemsItems } from "reducer/action/coreActions";
import { forceReload } from 'reducer/action/coreActions';

import K8S from '../lib/k8s'
import UserAPI from "../lib/keepsec_auth_service"
import UserMgt from '../lib/user_managment'
import { ThemeContext } from '../theme/ThemeContext';
import Utils from '../utils'
import Breadcrumb from './Breadcrumb';
import { Toast as Toast1 } from './Notify';

const CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID || "bd6c8852-9c10-4a07-be44-d0a696adfbdf"

function CustomNotificationCenter() {
    const dispatch = useDispatch()
    const { socket } = useSocket();
    async function getServerList(){
        await UserMgt.get_user_vps()
        await K8S.getK8sInstance()
    }

    useEffect(() => {
        if (socket) {
          socket.on("notification_received", (data) => {
            console.log('Notification received ', data)
            if(data?.message?.templateIdentifier === "test-notification"){ 
                Toast1.fire({
                    icon: 'success',
                    title: data.message?.content
                })
                getServerList()
                dispatch(forceReload())

            }  
          });
        }

        return () => {
          if (socket) {
            socket.off("notification_received");
          }
        };
    }, [socket]);

  return <></>;
}

const Content = () =>{
    const { t, i18n } = useTranslation();
    const { theme, setTheme } = React.useContext(ThemeContext);
    const dispatch = useDispatch();
    const { dontDisplayAllItems } = useSelector(app => app.core)
    const [langs, setLangs] = useState([])

    const detectTheme = () => {
        if (window.matchMedia) {
          if(window.matchMedia('(prefers-color-scheme: dark)').matches){
            return "dark"
          } else {
            return "light"
          }
        } else {
            return null
        }
    }

    
    useEffect(() => {
        setLangs([
            {
              value: 'default',
              label: t("core.navbar.settingsPopup.browserDefault"),
              disabled: true
            },
            {
              value: 'en',
              label: t("core.navbar.settingsPopup.en"),
            },
            {
              value: 'fr',
              label: t("core.navbar.settingsPopup.fr"),
            },
        ])
    }, [i18n.language])

    return(
        <div className="w-[22rem] px-3 py-2 ">
            <div className="mt-3">
                <span className="font-semibold">{t("core.navbar.settingsPopup.language")}</span> 
                <div className="mt-3">
                    <Select
                        size="large"
                        className="w-full rounded-none"
                        placeholder={t("core.navbar.settingsPopup.browserDefault")}
                        onChange={(lang) => {
                            let newLan = lang
                            if(lang==='default'){
                                newLan = navigator.language || navigator.userLanguage;
                            }
                            i18n.changeLanguage(newLan)
                            Utils.setCookieShared("i18n_redirected", ['fr', 'en'].includes(i18n?.language) ? i18n?.language : "en")
                        }}
                        value={!["fr", "en"].includes(i18n?.language) ? "default" :  i18n?.language}
                        options={langs}
                        defaultValue={!['fr', 'en'].includes(i18n?.language) ? "default" : i18n?.language}
                    />
                </div>
            </div>

            <div className="mt-5">
                <span className="font-semibold">{t("core.navbar.settingsPopup.viewMode")}</span>
                <div className="mt-2">
                    <Radio.Group onChange={(e)=>setTheme(e.target.value)} value={theme}>
                        <Space direction="vertical">
                            <Radio 
                                className="font-bold" 
                                value={detectTheme()}
                            >
                               {t("core.navbar.settingsPopup.browserDefault")}
                            </Radio>
                            <Radio className="font-bold" value={"light"}>{t("core.navbar.settingsPopup.light")}</Radio>
                            <Radio className="font-bold" value={"dark"}>{t("core.navbar.settingsPopup.dark")}</Radio>
                        </Space>
                    </Radio.Group>
                </div>
            </div>
            <div className={`pt-3 ${!Utils.isAdmin() && "hidden"}`}>
                <Checkbox
                    checked={!!dontDisplayAllItems}
                    onChange={(e) => {
                        dispatch(dontDisplayAllItemsItems(!!e.target.checked))
                    }}
                >
                    Demo mode
                </Checkbox>
            </div>
            <div className="pt-2">
                <Divider />
            </div>
            <div className="pt-2">
                <Link to="/profile" className="font-semibold">{t("core.navbar.settingsPopup.more")}</Link>
            </div>
        </div>
    )
}

export default function AdminNavbar({ setShowSidebar }) {
    const navigate = useNavigate()
    const user = useSelector(app => app.user)
    const { theme } = React.useContext(ThemeContext);
    const { t, i18n } = useTranslation();
    async function onLogout() {
        const result = await UserAPI.logout({ token: user?.refreshToken })
        if(result){
            if(window.$crisp){
                window.$crisp.push(["do", "session:reset"])
            }
            window.location.reload()
        }else{
            Toast1.fire({
                icon: 'error',
                title: t("core.constants.STANDARD_ERROR_MESSAGE")
            })
        }
    }

    function isValidPhoneNumber(phoneNumber) {
        const regex = /^(?:\+?\d{1,3}[-.\s]?)?(?:\(?\d{1,4}?\)?[-.\s]?)?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/; 
        return regex.test(phoneNumber);
    }

    useEffect(() => {
        Crisp.configure(CRISP_WEBSITE_ID);
        window.$crisp?.push(["set", "user:email", [user?.user?.EmailAddr, user?.user?.emailSigned]]);
        window.$crisp?.push(["set", "user:nickname", [user?.user?.FirstName + (" " + user?.user?.LastName || "")]]);
        if (!!user?.user?.PhoneNumber && isValidPhoneNumber(user?.user?.PhoneNumber)) {
            window.$crisp?.push(["set", "user:phone", [user?.user?.PhoneNumber]]);
        }
        if (!!user?.user?.Address) {
            // window.$crisp?.push(["set", "user:address", [user?.user?.Address + (", " + user?.user?.Address2 || "") + (", " + user?.user?.City || "") + (", " + user?.user?.ZipCode || "")]]);
        }
        if (!!user?.user?.Company) {
            window.$crisp?.push(["set", "user:company", [user?.user?.Company || "", {
                url: user?.user?.Website || "",
                description: user?.user?.Description || ""
            }]]);
        }
    }, [user])

    const items = [
        {
          label: t("core.navbar.settingsPopup.myAccount"),
          key: '1',
          icon: <UserOutlined />,
        },
        {
          label: t("core.navbar.settingsPopup.logout"),
          key: '2',
          icon: <LogoutOutlined />,
        }
    ]
    
    
    
    const handleMenuClick = (e) => {
        if(e.key === "1") return navigate('/profile')
        if(e.key === "2") return onLogout()
    
    };
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    // const langs = [
    //     {label: t("core.navbar.settingsPopup.en"), value: "en"},
    //     {label: t("core.navbar.settingsPopup.fr"), value: "fr"}
    // ];

    

    return (
        <nav className="bg-neutral-100 z-10 absolute w-screen top-0 left-0 dark:bg-darkSiderbar pl-2 pr-2 md:pl-8 md:pr-10">
            
            <div className="container max-w-full mx-auto flex items-center justify-between">
                <div className="flex justify-between items-center w-full">
                    <div className='flex items-center py-3  gap-2'>
                        <Breadcrumb />
                    </div>
                    <div className="flex items-center gap-4">
                        <NovuProvider 
                            subscriberId={user?.user?.Id} 
                            applicationIdentifier={"wXYj6y4VCfMW"}
                            i18n={['fr', 'en'].includes(i18n?.language) ? i18n?.language : "en"}
                            
                        >
                            <PopoverNotificationCenter 
                                colorScheme={theme  }
                              
                            >
                                {({ unseenCount }) =>  {
                                        if(unseenCount){
                                            return (
                                                <Badge count={unseenCount} className="mt-2" >
                                                    <MdNotifications className="cursor-pointer text-primary text-2xl" /> 
                                                </Badge>
                                            )
                                        }
                                        return <MdNotifications className="cursor-pointer text-primary text-2xl" /> 
                                    }
                                }
                            </PopoverNotificationCenter>
                            <CustomNotificationCenter />
                        </NovuProvider>


                        <QuestionCircleFilled className="text-primary text-xl cursor-pointer" />

                        <Popover className="" placement="bottomRight" trigger="click" content={() =><Content />} title="">
                            <div className="w-7  flex items-end">   
                                <SettingFilled className=" text-primary text-xl cursor-pointer" />
                            </div>
                        </Popover>

                        

                        <div className="-mr-4 ml-6 hidden md:flex">
                            <div className="flex justify-center">
                                <div>
                                <Dropdown trigger={"click"} menu={menuProps}>
                                    <Button className='min-w-[160px] rounded-[2px] shadow uppercase text-xs font-semibold	' type="primary">
                                        <Space>
                                            {user?.user?.FirstName}
                                        <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="md:hidden">
                            <div>
                                <input className="menu_hamburger1"  onClick={() => setShowSidebar()} type="checkbox" id="menu_hamburger1"/>
                                <label htmlFor="menu_hamburger1">
                                    <span></span>
                                </label>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

