import { Button, Checkbox, Col, Input, Modal, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ExtLink } from 'components/Bloc';
import gfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import CAPTAIN from '../../../lib/libCaprover';
import Utils from '../../../utils';
import { useTranslation } from "react-i18next";
import { HelpIcon } from "components/Bloc";

const Search = Input.Search


function BasicAuthModal(props){
	const auth = props?.app?.httpAuth
	const {t}  = useTranslation()
	useEffect(()=>{
    	props?.setTempBasicAuth({
            dialogHttpPass: auth ? auth.password || '' : '',
            dialogHttpUser: auth ? auth.user || '' : '',
        })
	}, [])

	return(
		<Modal
        destroyOnClose={true}
        footer={null}
        centered
        closeIcon={false}
        className='mPadding'
        width={500}
        open={props?.basicAuthModalDisplay}
    >
        <div className=''>
            <div className='bg-primary py-5 px-6 text-white'>
                <div className=''>
                    <h4 className="font-bold text-base xl:text-xl">
                         {t('platform.configPage.httpSettingsTab.basicAuthModal.title')}
                    </h4>
                    <p className='text-sm xl:text-base'>
                        {/*Select new instance to attach. */}
                    </p>
                </div>
                
            </div>
            <div className="overflow-y-auto ">
            	<div className="w-full px-6 py-6">
            		<p>
						{t('platform.configPage.httpSettingsTab.basicAuthModal.p1')}
                    </p>
                    <p>
						{t('platform.configPage.httpSettingsTab.basicAuthModal.p2')}
                    </p>
					<br/>
                    <p>
                        <Input
                            placeholder={t('platform.configPage.httpSettingsTab.basicAuthModal.username')}
                            type="text"
                            defaultValue={props?.tempBasicAuth?.dialogHttpUser}
                            onChange={(event) =>
                                props?.setTempBasicAuth({
                                	...props?.tempBasicAuth, 
                                    dialogHttpUser: (
                                        event.target.value || ''
                                    ).trim(),
                                })
                            }
                        />
                    </p>
                    <p className="pt-3">
                        <Input
                            placeholder={t('platform.configPage.httpSettingsTab.basicAuthModal.password')}
                            type="text"
                            defaultValue={props?.tempBasicAuth?.dialogHttpPass ? "[hidden]" : ""}
                            onChange={(event) =>
                                props?.setTempBasicAuth({
                                	...props?.tempBasicAuth,
                                    dialogHttpPass: (
                                        event.target.value || ''
                                    ).trim(),
                                })
                            }
                        />
                    </p>					
				</div>
            </div>
            <div className="py-5 flex gap-2 justify-end  px-6">
               
                	<Button size="large" onClick={() => {
                          props?.setOpenBasicAuth(false); 
                        }}
                    >
                        {t('platform.configPage.httpSettingsTab.basicAuthModal.cancel')}
                    </Button>
                    <Button 
                    	className="flex items-center gap-2" 
                    	size="large" type="primary"  
                    	onClick={() => {
                            props?.onConfirmBasicAuth()
                        }}
                    >
                        {t('platform.configPage.httpSettingsTab.basicAuthModal.confirm')}
                    </Button>
        		
        	</div>
        </div>
    </Modal>
	)
}

export default function Http(props){
	const [loadhttp, setLoadhttp] = useState(false)
	const [newDomain, setNewDomain] = useState(false)
	const [tempBasicAuth, setTempBasicAuth] = useState({})
	const {messageApi} = useSelector(app => app.core )
	const {t} = useTranslation()
	const [httpsSubdomainEnabled, setHttpsSubdomainEnabled] = useState({})
	const [httpsSubdomainRemoved, setHttpsSubdomainRemoved] = useState({})
	const [app, setApp] = useState(null)
	const [basicAuthModalDisplay, setOpenBasicAuth] = useState(null)
    const rootDomain = props?.apiData?.rootDomain
    const basicAuthUsername = props?.apiData?.appDefinition.httpAuth
            ? props?.apiData?.appDefinition.httpAuth.user
            : ''

	useEffect(()=>{
	    setApp(props?.apiData?.appDefinition)
	}, [props?.apiData?.appDefinition])

    const enableDefaultHttps = () => {
        setLoadhttp(true)

        return Promise.resolve()
            .then(function () {
                return CAPTAIN.enableSslForBaseDomain(
                    app?.appName
                )
            })
            .then(function (data) {
				console.log('data data data enabled https', data)
				if(!data?.error || [100, 101, 102].includes(data?.status)){
			        messageApi.success(
			            t('platform.configPage.httpSettingsTab.httpsEnabledSuccessMessage')
			        )
		        }else{
		        	messageApi.error(
			            data?.description || data?.message || t('core.constants.STANDARD_ERROR_MESSAGE')
			        )
		        }
            })
            .then(function (error) {
                setLoadhttp(false)
				if(error) return
                props.reFetchData()
            })
            .catch((e)=>{
            	console.log('error https', e)
				messageApi.error(t('core.constants.STANDARD_ERROR_MESSAGE'))
	                setLoadhttp(false)
               	}
            )
    }

    async function onConfirmBasicAuth() {
    	const newApiData = Utils.copyObject(props.apiData)
        const enteredUser = tempBasicAuth.dialogHttpUser
        const enteredPassword = tempBasicAuth.dialogHttpPass

        if (!enteredUser || !enteredPassword) {
            newApiData.appDefinition.httpAuth = undefined
        } else {
            newApiData.appDefinition.httpAuth = newApiData.appDefinition
                .httpAuth || { user: '' }

            newApiData.appDefinition.httpAuth.user = enteredUser
            newApiData.appDefinition.httpAuth.password = enteredPassword
        }
        props.updateApiData(newApiData)
        setOpenBasicAuth(false)
        return props.onUpdateConfigAndSave()
    }
    async function onRemoveCustomDomainClicked(customDomain) {
        setHttpsSubdomainRemoved({[customDomain]: true})
        const globalData = {
        	path: "/user/apps/appDefinitions/removecustomdomain",
        	data: {
                appName: app?.appName,
                customDomain,
            }
        }
        const result = await CAPTAIN.globalPoster(globalData);
        props.reFetchData()
        setHttpsSubdomainRemoved({})
        console.log(' result result ', result)
        if(!result?.error || [100, 101, 102].includes(result?.status)){
	        messageApi.success(
				t('platform.configPage.httpSettingsTab.removeCustomDomainSuccesssMessge')
	        )
        }else{
        	messageApi.error(
	            result?.description || result?.message || t('core.constants.STANDARD_ERROR_MESSAGE')
	        )
        }
	}
    //test.geekcorp.ca
    async function onEnableCustomDomainSslClicked(customDomain) {
        setHttpsSubdomainEnabled({[customDomain]: true})
        const globalData = {
        	path: "/user/apps/appDefinitions/enablecustomdomainssl",
        	data: {
                appName: app?.appName,
                customDomain,
            }
        }
        const result = await CAPTAIN.globalPoster(globalData);
        setHttpsSubdomainEnabled({})
        console.log(' result result ', result)
        if(!result?.error || ![100, 101, 102].includes(result?.status)){
	        messageApi.success(
				t('platform.configPage.httpSettingsTab.httpsActivatedSuccessMssg')
	        )
        }else{
        	messageApi.success(
	            result?.description || t('core.constants.STANDARD_ERROR_MESSAGE')
	        )
        }
		props.reFetchData()
    }

    function onEditDefaultNginxConfigClicked() {
        const newApiData = Utils.copyObject(props.apiData)
        newApiData.appDefinition.customNginxConfig = props.apiData?.defaultNginxConfig
        props.updateApiData(newApiData)
    }

    function createCustomNginx() {
        const customNginxConfig = app?.customNginxConfig
        if (!customNginxConfig) {
            return (
                <div>
                    <Button
                        type="default"
                        onClick={() => onEditDefaultNginxConfigClicked()}
                    >
						{t('platform.configPage.httpSettingsTab.editnginxConfigBtn')}
                    </Button>
                </div>
            )
        }

        return (
            <div>
				<div
					 dangerouslySetInnerHTML={{ __html: t('platform.configPage.httpSettingsTab.dangerousHtml1') }}
				/>
				<br />
                <Input.TextArea
                    style={{
                        fontFamily: 'monospace',
                    }}
                    onChange={(e) => {
                        const newApiData = Utils.copyObject(props.apiData)
                        newApiData.appDefinition.customNginxConfig = e.target.value
                        props.updateApiData(newApiData)
                    }}
                    rows={17}
                    defaultValue={customNginxConfig}
                />
            </div>
        )
    }
    async function onConnectNewDomainClicked(customDomain) {
    	setNewDomain(true)
        const globalData = {
        	path: "/user/apps/appDefinitions/customdomain",
        	data: {
                appName: app?.appName,
                customDomain,
            }
        }
        const result = await CAPTAIN.globalPoster(globalData);
		props.reFetchData()
        setNewDomain(false)
        console.log(' result result ', result)
        if(result?.error || ![100, 101, 102].includes(result?.status)){
        	messageApi.error(
	            result?.description || result?.message || t('core.constants.STANDARD_ERROR_MESSAGE')
	        )
        }else{
	        messageApi.success(
				t('platform.configPage.httpSettingsTab.newDomainConnectedSUccessMessage')
	        )
        }
    }
    function createCustomDomainRows() {
        const customDomains = app?.customDomain || []

        const rows =  []
        customDomains.forEach((c) => {
            const row = (
                <Row key={c.publicDomain} style={{ marginTop: 15 }}>
                    <Button.Group size="small">
                        <Tooltip
                            title={
                                c.hasSsl
                                    ? t('platform.configPage.httpSettingsTab.alreadyACtivated')
                                    : t('platform.configPage.httpSettingsTab.clickToActive')
                            }
                        >
                            <Button
                                // disabled={c.hasSsl}
                                onClick={() => {
                                	if(!c.hasSsl){
	                                    onEnableCustomDomainSslClicked(
	                                        c.publicDomain
	                                    )
                                	}
                                }}
                                loading={httpsSubdomainEnabled?.[c.publicDomain]}
                                type="primary"
                            >
                                {c.hasSsl ? t('platform.configPage.httpSettingsTab.htppsEnabled') : t('platform.configPage.httpSettingsTab.enabledHtpps')}
                            </Button>
                        </Tooltip>
                        <Button
                            style={{ marginRight: 20 }}
                            loading={httpsSubdomainRemoved?.[c.publicDomain]}

                            onClick={() => {
                                onRemoveCustomDomainClicked(c.publicDomain)
                            }}
                        >
                            {t('platform.configPage.httpSettingsTab.removeDomainBtn')}
                        </Button>
                    </Button.Group>
					<ExtLink 
						href={`http${c.hasSsl? "s":""}://${c.publicDomain}`}
						text={c.publicDomain}
						className="text-blue-500 md:ml-[15px]"
						rel="noopener noreferrer"
					/>  
                </Row>
            )
            rows.push(row)
        })

        return rows
    }

	return(
		<div className=" ">
			<div
				dangerouslySetInnerHTML={{ __html: t('platform.configPage.httpSettingsTab.dangerousHtml2', {appName: app?.appName}) }}
			/>
            <br/>
            <BasicAuthModal 
            	app={app}
            	setTempBasicAuth={setTempBasicAuth}
            	basicAuthModalDisplay={basicAuthModalDisplay}
            	tempBasicAuth={tempBasicAuth}
            	setOpenBasicAuth={setOpenBasicAuth}
            	onConfirmBasicAuth={onConfirmBasicAuth}
            />


            <div className="" >
            	{app &&
		            <Checkbox
		                defaultChecked={
		                    app?.notExposeAsWebApp
		                }
		                onChange={(e) => {
		                    const newApiData = Utils.copyObject(props?.apiData)
		                    newApiData.appDefinition.notExposeAsWebApp =
		                        !!e.target.checked
		                    props.updateApiData(newApiData)
		                }}
		            >
						{t('platform.configPage.httpSettingsTab.doNotExposeApp')}
		            </Checkbox>
            	}
				<HelpIcon 
					message={t("platform.configPage.httpSettingsTab.dontExposeHelpMessage")}
				/>
	        </div>
		    {!app?.notExposeAsWebApp &&
		    	<div>
				    <p className="pt-6">{t("platform.configPage.httpSettingsTab.appPublicAvailableUrl")}:</p>

				    <br/>
				    
				    <Row>
	                    <Button.Group size="small">
	                        <Tooltip
	                            title={
	                                app?.hasDefaultSubDomainSsl
									? t('platform.configPage.httpSettingsTab.alreadyACtivated')
                                    : t('platform.configPage.httpSettingsTab.clickToActive')
	                            }
	                        >
	                            <Button
	                                onClick={() => {
	                                	if(app?.hasDefaultSubDomainSsl){
	                                		messageApi.success(t('platform.configPage.httpSettingsTab.sslActivtedMessage'))
	                                	}else{
	                                    	enableDefaultHttps()
	                                	}
	                                }}
	                                type="primary"
	                                loading={loadhttp}
	                            >
	                                {app?.hasDefaultSubDomainSsl ?
	                                	t('platform.configPage.httpSettingsTab.htppsEnabled') : t('platform.configPage.httpSettingsTab.enabledHtpps')
	                                }
	                            </Button>
	                        </Tooltip>
	                    </Button.Group>
						
						<ExtLink 
							href={`http${app?.hasDefaultSubDomainSsl ? 's' : ''}://${
	                            app?.appName
	                        }.${rootDomain}`}
							text={`http${app?.hasDefaultSubDomainSsl ? 's' : ''}://${
								app?.appName
							}.${rootDomain}`}
							className="text-blue-500 md:ml-[15px]"
							rel="noopener noreferrer"
						/>

				    </Row>
				    <div>
				    	{createCustomDomainRows()}
				    </div>
				    <br/>
				    <Row>
	                    <Col xs={{ span: 24 }} lg={{ span: 15 }}>
                            <Search
                                placeholder="www.the-best-app-in-the-world.com"
                                enterButton={t("platform.configPage.httpSettingsTab.connectNewDomain")}
                                onSearch={(value) =>{
                                    onConnectNewDomainClicked(value)
                                }}
                                loading={newDomain}
                            />
	                    </Col>
	                    &nbsp;&nbsp;&nbsp;
						<HelpIcon className="mt-[9px]" message={t("platform.configPage.httpSettingsTab.newDomainHelpMessage")} />
	                </Row>

	                <br />
	                	{createCustomNginx()}
	                <br />
	                <div className="md:w-[300px] w-full">
	                	{app && 

	                        <Tooltip 
								title={t('platform.configPage.httpSettingsTab.containerPortHelpMessage')}
							>
	                            <Input
	                                addonBefore={t(`platform.configPage.httpSettingsTab.containerPortBefore`)}
	                                type="number"
	                                defaultValue={
	                                    app?.containerHttpPort
	                                        ? app?.containerHttpPort + ''
	                                        : ''
	                                }
	                                onChange={(e) => {
	                                    const newApiData = Utils.copyObject(
	                                        props.apiData
	                                    )
	                                    newApiData.appDefinition.containerHttpPort = Number(e.target.value)
	                                    props.updateApiData(newApiData)
	                                }}
	                            />
	                        </Tooltip>
	                	}
	                </div>

	                <br />

	                {typeof app?.forceSsl !== "undefined" &&
		                <Row>
		                    <Checkbox
		                        defaultChecked={app?.forceSsl}
		                        onChange={(e) => {
		                            const newApiData = Utils.copyObject(
		                                props.apiData
		                            )
		                            newApiData.appDefinition.forceSsl =
		                                !!e.target.checked
		                            props.updateApiData(newApiData)
		                        }}
		                    >
								{t('platform.configPage.httpSettingsTab.forceRedirectHtpps')}
		                    </Checkbox>
							<HelpIcon 
								message={t('platform.configPage.httpSettingsTab.forceRedirectHttpsTooltip')}
							/>
		                </Row>
		            }
	                <br />
	                {typeof app?.websocketSupport !== "undefined" &&
		                <Row>
		                    <Checkbox
		                        defaultChecked={app?.websocketSupport}
		                        onChange={(e) => {
		                            const newApiData = Utils.copyObject(
		                                props.apiData
		                            )
		                            newApiData.appDefinition.websocketSupport =
		                                !!e.target.checked
		                            props.updateApiData(newApiData)
		                        }}
		                    >
								{t('platform.configPage.httpSettingsTab.websockedtSupport')}
		                    </Checkbox>
							<HelpIcon message={t('platform.configPage.httpSettingsTab.websockedtSupportTooltip')} />
		                </Row>
	                }
	                <br />

	                <div className="flex items-center">
	                    <Button
	                        style={{ marginRight: 20 }}
	                        type="default"
	                        onClick={() => setOpenBasicAuth(true)}
	                    >
	                        
							{t('platform.configPage.httpSettingsTab.editBasicAuth')}
	                    </Button>
	                    <div className="h-[10px] md:h-[0px] " />
	                    <span>
						{t('platform.configPage.httpSettingsTab.currentState')}:{' '}
	                        <b>{!basicAuthUsername ? t('platform.configPage.httpSettingsTab.inactive') : t('platform.configPage.httpSettingsTab.active')}</b>{' '}
	                        {basicAuthUsername
	                            ? `[user: ${basicAuthUsername}@password: <HIDDEN>]`
	                            : ''}
	                    </span>
	                </div>

		            <h3 className="text-xl font-semibold"></h3>
		            <div className="mx-auto gap-4 justify-center pb-5  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3    mt-10">
		                
		             </div>
		        </div>
		    }
        </div>
	)
}






