import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { BsFillLightbulbFill } from "react-icons/bs";

import { Spiner } from 'components/Bloc';
import { Texto } from 'layouts/TextoLayout';

import Utils from '../../utils';

export default function TrialBottom(props){
    const {t} = useTranslation()
    const myObserver = new ResizeObserver(entry => {
        if(props.setBottomCalculedHeight){
            props.setBottomCalculedHeight(window.$('#TrialBotomAdd').height())
        }
    });
    useEffect(() =>{
        const someEl = document.getElementById('TrialBotomAdd');
        myObserver.observe(someEl); 
        return () => myObserver.disconnect();
    }, [])
 
    function calculVat(){
        if(!props.totalPrice && !props.packSelected){
            return null
        }
        return (Utils.priceConvert(parseFloat(props.totalPrice || props.packSelected?.Price)) * (14.975 / 100)).toFixed(2)
    }

    function finalPrice(){
        if(!props.totalPrice && !props.packSelected){
            return null
        }
        return (Utils.priceConvert(parseFloat(props.totalPrice || props.packSelected?.Price)) * (1 + 14.975 / 100)).toFixed(2)
    }

    const renderCurency = (veryMin) => (
        <span className={`${!veryMin ? "text-xs": "text-[11px]"}  font-bold`}>
            {Utils.renderCurencyCode()}
        </span>
    )

   
    return( 
        <div className='mx-auto'>
  

            <div id="TrialBotomAdd" className={' fixed py-3 bottom-0 z-[5] dark:opacity-70 left-0  md:left-60 2xl:left-72 right-3 dark:text-darkTextColor dark:bg-bgPagedark   shawdow  '}>
                
                <div className='flex flex-wrap gap-4 items-end justify-center'>
                    
                    <div className={`w-[450px] text-left text-sm 2xl:text-base text-primary dark:text-darkTextColor `} >
                        <Texto className='font-bold'>{t("deploy.deployBottom.summary")}:</Texto> 
                        {!props?.isKube ?
                            <>
                                <div className='flex gap-2'>
                                    {t("deploy.deployBottom.location")}:{" "}
                                    <Texto small className='font-semibold'>
                                        <>{props.vps_params?.locationO?.name || t("deploy.deployBottom.valueNotDefined") }</>
                                    </Texto>
                                </div>
                                {!props?.isKps && !props?.isSecurity && !props?.isNetwork ?
                                    <div className='flex gap-2'>
                                        {t("deploy.deployBottom.image")}:{" "}
                                        <Texto small className='font-semibold'>
                                                <>{props.vps_params?.image || t("deploy.deployBottom.valueNotDefined") }</>
                                        </Texto>
                                    </div>
                                    :
                                    null
                                } 
                                <Texto small className='font-semibold'>
                                    {t("deploy.deployBottom.plan")}:{" "}
                                    <>{
                                        props.packSelected?
                                            <>
                                                {props.packSelected?.Name}
                                                &nbsp;-&nbsp;
                                                {renderCurency(true)}
                                                {Utils.priceConvert(props.packSelected?.Price).toFixed(2)}{" "}
                                                {t("deploy.deployBottom.perMonth")}
                                            </>
                                            : 
                                            t("deploy.deployBottom.valueNotDefined") 
                                        }
                                    </>
                                </Texto> 
                            </>
                            :
                            null
                        }
                        <Texto small className='font-semibold'>
                            TPS + TVQ:{" "}
                            {calculVat() ? 
                                <>
                                    {renderCurency(true)}
                                    {calculVat()}
                                </> 
                                : 
                                t("deploy.deployBottom.valueNotDefined")
                            }
                        </Texto> 
                        <>
                            <span className='text-sm 2xl:text-base font-bold'>
                                {t("deploy.deployBottom.tAmount")}: &nbsp;
                            </span>
                            <span className={`text-sm font-bold text-primary ${!props.vps_params?.trialCode && "hidden"} `}>
                                {t("deploy.deployBottom.day7FreeThen", {then: finalPrice () ? t('deploy.deployBottom.then'): ''})} &nbsp;
                            </span>
                            {finalPrice () ?
                                <>
                                    {renderCurency()}
                                    <span className='text-base xl:text-xl 2xl:text-2xl font-bold'>
                                        {finalPrice()}
                                    </span>
                                </>
                                :
                                <span className='font-semibold'>
                                    
                                </span>
                            }
                            <span className="font-semibold text-xs xl:text-sm">
                                { finalPrice () ? t("deploy.deployBottom.perMonth") : ""}
                            </span>
                        </>
                    </div>
                    <div className=''>
                        <div className=''>
                            <label
                                className="
                                        block
                                        uppercase
                                        tracking-wide
                                        text-gray-700 text-xs
                                        dark:text-darkTextColor
                                        font-bold
                                        mb-2
                                    "
                                htmlFor="trialCode"
                            >
                                {t("deploy.deployBottom.trialCodeLabel")}
                            </label>
                            <input
                                className="
                                    appearance-none
                                    block
                                    w-full
                                    bg-gray-200
                                    text-gray-700
                                    border
                                    rounded
                                    py-3
                                    px-4
                                    mb-3
                                    leading-tight
                                    dark:bg-bgPagedark
                                    dark:text-darkTextColor
                                    focus:outline-none focus:bg-white
                                "
                                id="trialCode"
                                value={props.vps_params.trialCode}
                                name="trialCode"
                                onChange={props.handleChange}
                                disabled={props.creating}
                                placeholder={t("deploy.deployBottom.trialCodePlaceholder")}
                            />
                        </div>

                        <button className="
                            flex 
                            mx-auto 
                            2xl:text-xl 
                            xl:text-base 
                            text-sm  
                            w-60 
                            xl:w-64  
                            2xl:w-72 
                            text-center
                            items-center
                            shadow
                            bg-primary
                            focus:shadow-outline 
                            focus:outline-none 
                            text-white 
                            font-bold 
                            2xl:py-4 2x:px-4 
                            xl:py-3 xl:px-3
                            py-2 px-2
                            rounded
                            "
                            type="submit"
                            disabled={props.creating}
                        >
                            {!props.creating ?
                                <span className='mx-auto flex  items-center'>
                                    <BsFillLightbulbFill className='text-2xl' />
                                    { props.soon ? t("deploy.deployBottom.comingSoon") : t("deploy.deployBottom.deployNow")}
                                </span>
                                :
                                <span className='mx-auto flex gap-1 items-center'>
                                    <Spiner customClass="text-white" fontSize={20} />
                                    {!props.paypal ? t("deploy.deployBottom.deploying") :  t("deploy.deployBottom.waitingForpaypal")}
                                </span>

                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
