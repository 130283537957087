import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';

import { ExtLink } from 'components/Bloc';

import { Logo } from '../../components/Logo';
import { Toast } from '../../components/Notify';
import UserAPI from "../../lib/keepsec_auth_service"
import UserMgt from "../../lib/user_managment"

import "./Auth.css"

export default function Register() {
    const { t } = useTranslation();
    const [objectRegister, setObject] = useState({})
    const [creating, setCreating] = useState(false)
    const navigate = useNavigate()
    const user =  useSelector(app => app?.user?.user)



    useEffect(() => {
        
        if (user) return navigate('/')

    }, [])
    const handleChange = (ev) => {
        const { value, name } = ev.target
        setObject({ ...objectRegister, [name]: value })
    }

 
    async function onRegister(e) {
        e.preventDefault()
        const referrerId = localStorage.getItem('referrerId')
        const newUser = { ...objectRegister, sendOffers: true, referrerId: referrerId}
        // if (newUser.password !== newUser.passwordConfirm) {
        //     return Toast.fire({
        //         icon: 'error',
        //         title: "Password did not match !"
        //     })
        // }
        if (newUser?.Password.length < 6) {
            return Toast.fire({
                icon: 'error',
                title: t("The password must be at least 6 characters !")
            })
        }

        setCreating(true)

        try {
            const result = await UserMgt.user_create(newUser)
            setCreating(false)
            if (!result || !result.success) {
                return Toast.fire({
                    icon: 'error',
                    title: result?.message || "An orrors occurs, please try again !"
                })
            } else {
                const result = await UserAPI.user_login({password: newUser.Password, email: newUser.EmailAddr})
                if(referrerId) localStorage.removeItem('referrerId')
                Toast.fire({
                    icon: 'success',
                    title: t('Success! Please check your a mailbox and active your account !')
                })
                if(!result.error){
                    navigate('/')
                }else{
                    navigate('/login')
                }
            }
        } catch (error) {
            setCreating(false)
            console.log('error api', error)
            Toast.fire({
                icon: 'error',
                title: error.message
            })
        }
        // navigate('/')
    }
    return (
        <div className='bgCustom h-screen overflow-y-auto'>
            <div className="flex items-center justify-center min-h-screen content">
                <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-2xl w-[400px]">
                    <div>
                        <Logo />
                        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
                            {t('Sign Up with Email')}
                        </h2>
                        <p className="mt-2 text-sm text-center text-gray-600">
                            {t('Sign up with')} {" "}
                            <NavLink
                                to="/registrations/new"
                                className={"font-medium text-primary hover:text-blue-500 cursor-pointer"}
                            >
                                Google
                            </NavLink>
                            {" "+t("or")+" "}
                            <NavLink
                                to="/registrations/new"
                                className={"font-medium text-primary hover:text-blue-500 cursor-pointer"}
                            >
                                Github
                            </NavLink>
                            {" "} {t('instead')}
                        </p>
                    </div>
                    <form className="mt-8 space-y-3" method="POST" onSubmit={onRegister}>

                        <div className="flex flex-wrap -mx-3">
                            <div className="w-full px-2 mb-0 md:mb-0">
                                <label className='font-semibold' htmlFor="FirstName">
                                    {t('Full name')} 
                                <span className="text-red-500">*</span></label>
                                <Input 
                                    className="
                                        w-full
                                        bg-gray-200s
                                        text-gray-700
                                        rounded-md
                                        py-1
                                        px-4
                                        dark:bg-transparent
                                    "
                                    value={objectRegister.FirstName}
                                    onChange={handleChange}
                                    name='FirstName'
                                    required
                                    type="text"
                                    id="FirstName"
                                    placeholder={t("Enter a full name")}
                                />
                            
                            </div>
                        </div>

                        <div className="flex flex-wrap -mx-3">
                            <div className="w-full px-2 mb-0 md:mb-0">
                                <label className='font-semibold' htmlFor="EmailAddr">{t('Email address')} <span className="text-red-500">*</span></label>
                                <Input 
                                    className="
                                        w-full
                                        bg-gray-200s
                                        text-gray-700
                                        rounded-md
                                        py-1
                                        dark:bg-transparent
                                        px-4
                                    "
                                    value={objectRegister.EmailAddr}
                                    onChange={handleChange}
                                    name='EmailAddr'
                                    required
                                    type="email"
                                    id="EmailAddr"
                                    placeholder={t("Enter your email address")}
                                />
                            
                            </div>
                        </div>
                        
                        <div className="flex flex-wrap -mx-3 ">
                            <div className="w-full px-2 mb-0 md:mb-0">
                                <label className='font-semibold' htmlFor="Password">{t('Password')} <span className="text-red-500">*</span></label>
                                <Input 
                                    className="
                                        w-full
                                        bg-gray-200s
                                        text-gray-700
                                        rounded-md
                                        dark:bg-transparent
                                        py-1
                                        px-4
                                    "
                                    value={objectRegister.Password}
                                    onChange={handleChange}
                                    name='Password'
                                    required
                                    type="password"
                                    id="Password"
                                    placeholder={t("Enter a password")}
                                />
                            
                            </div>

                            {/* <div className="w-full px-3  md:mb-0">
                                <label
                                    className="
                                    block
                                    uppercase
                                    tracking-wide
                                    text-gray-700 text-xs
                                    font-bold
                                    mb-2
                                "
                                    htmlFor="grid-first-name"
                                >
                                    Password
                                </label>
                                <input
                                    value={objectRegister.password}
                                    onChange={handleChange}
                                    name='password'
                                    className="
                                    appearance-none
                                    block
                                    w-full
                                    bg-gray-200
                                    text-gray-700
                                    border
                                    rounded
                                    py-3
                                    px-4
                                    mb-3
                                    leading-tight
                                    focus:outline-none focus:bg-white
                                "
                                    id="grid-first-name"
                                    type="password"
                                    placeholder="***********"
                                    required

                                />
                                <p className="text-red-500 text-xs italic">
                                    Make it as long and as crazy as you'd like.
                                </p>
                            </div> */}
                            {/* <div className="w-full md:w-1/2 px-3">
                                <label
                                    className="
                                    block
                                    uppercase
                                    tracking-wide
                                    text-gray-700 text-xs
                                    font-bold
                                    mb-2
                                "
                                    htmlFor="confirm"
                                >
                                    Confirm password
                                </label>
                                <input
                                    className="
                                    appearance-none
                                    block
                                    w-full
                                    bg-gray-200
                                    text-gray-700
                                    border border-gray-200
                                    rounded
                                    py-3
                                    px-4
                                    leading-tight
                                    focus:outline-none focus:bg-white focus:border-gray-500
                                "
                                    required
                                    value={objectRegister.passwordConfirm}
                                    onChange={handleChange}
                                    name='passwordConfirm'
                                    id="confirm"
                                    type="password"
                                    placeholder="***********"
                                />
                            </div> */}
                        </div>

                        <div className="flex flex-wrap -mx-3 mb-6"></div>

                        <div>
                            <button
                                type="submit"
                                className="
                                relative
                                flex
                                justify-center
                                w-full
                                px-4
                                py-2
                                text-sm
                                font-medium
                                text-white
                                bg-primary
                                group
                                rounded-md
                                hover:bg-black hover:border-primary
                                focus:outline-none
                                focus:ring-2
                                focus:ring-offset-2
                                focus:ring-blue-500
                                flex
                                gap-4
                                "
                            >
                                {!creating ?
                                    <svg
                                        className="w-5 h-5 text-blue-500 group-hover:text-indigo-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    :
                                    <svg className="animate-spin  -ml-1mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                }

                                {!creating ?
                                    <span>{t('Sign up')}</span>
                                    :
                                    <span>{t('Ongoing')} ...</span>
                                }
                            </button>
                        </div>
                    </form>

                    <div className="flex items-center justify-center flex-wrap gap-2 mt-3 text-xs">
                        {t('Already have account ?')}
                        <div className="flex gap-2 items-center ">
                            <Link
                                to="/login"
                                className={""}
                            >
                                <span className="font-bold text-primary hover:text-blue-500 cursor-pointer"
                                >
                                    {t('Log in')}
                                </span>
                            </Link>
                            <Link
                                to="/forgot_password/new"
                                className={""}
                            >
                                <span className="font-bold text-primary hover:text-blue-500 cursor-pointer"
                                >
                                    {t('Forgot your password?')}
                                </span>
                            </Link>
                        </div>

                    </div>
                    <div className="flex items-center justify-center text-center text-xs pt-5">
                        <span>
                            {t('By signing up, you agree to the')} {" "}
                            <ExtLink 
                                href='https://www.keepsec.ca/legal'
                                text={t('Terms of Service')}
                                className="font-bold text-xs 2xl:text-xs" 
                            />
                           {" "}  {t('and')} {" "}
                            <ExtLink 
                                href='https://www.keepsec.ca/legal?tab=privacy' 
                                text={t('Privacy Policy')}
                                className="font-bold text-xs 2xl:text-xs"
                            />
                        </span>
                    </div>

                </div>
            </div>


        </div>
    );
}












